import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { message, Alert, Button, Card, Form, Input, Select, Popconfirm } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import { isEmpty } from 'lodash';

import GA from '../../analytics/ga';
import { regions } from '../../shared/data/regions';
import { fetchBillingInfo, updateBillingInfo, connectPaymentMethod, makePayment, fetchProfile, removePaymentMethod } from '../../redux/actions/profileActions';
import Balance from './Balance';

const { Option } = Select;
const cardStyle = { width: 400 };

export default function BillingInformation({ location = {}, authForwarded = {} }) {
  const { search } = location;
  const { session_id, checkout, secondary_tab } = queryString.parse(search);

  const { profile: authProfile } = authForwarded || {};
  const { emailVerified, region, roles } = authProfile || {};
  const isSupport = roles.includes('support');

  const profile = useSelector(state => state.profileReducer.profile);
  const billingInfo = useSelector(state => state.profileReducer.billingInfo);
  const loading = useSelector(state => state.profileReducer.loading);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { balance = 0, account_status, risky = false } = profile;
  const accountNotValid = account_status? account_status !== 'valid' : false;

  const [formUpdated, setFormUpdated] = useState(false);
  const { payment_method = {}, source = {}, address, name, description } = billingInfo;
  const { bank_name, account_number, routing_number } = source;
  const { last4 } = payment_method;

  const hasPaymentMethodOrSource = last4 || bank_name;

  const [form] = Form.useForm();

  if (!formUpdated && !isEmpty(billingInfo)) {
    setFormUpdated(true);
    form.setFieldsValue(billingInfo);
  }

  useEffect(() => {
    document.title = t('title.billingInformation', 'Billing Information');

    if (checkout === 'success' && session_id) {
      if (secondary_tab && secondary_tab === 'true') {
        message.success(t('title.paymentMethodConnectedSecondTab', 'Payment method connected! Return to the previous tab to start your task'), 30);
      } else {
        message.success(t('title.paymentMethodConnected', 'Payment method connected'));
      }

      fetchData();
    } else if (checkout === 'true') {
      onConnectPaymentMethod();
      fetchData();
    } else {
      fetchData();
    }
  }, []);

  function fetchData() {
    dispatch(fetchBillingInfo());
    dispatch(fetchProfile());
  }

  function onConnectPaymentMethod() {
    const fromCheckout = checkout === 'true';
    dispatch(connectPaymentMethod(fromCheckout, region));
    GA.track('button', 'Connect payment intent', fromCheckout ? 'Task confirmation' : 'Billing');
  }

  function onRemovePaymentMethod() {
    dispatch(removePaymentMethod());
  }

  function onMakePayment(amount, region) {
    dispatch(makePayment(amount, region, secondary_tab === 'true'));
  }

  function onUpdateBillingInfo() {
    dispatch(updateBillingInfo(form.getFieldsValue()));
  }

  return (
    <>
      {accountNotValid && <p style={{ color: 'red' }}>{t('description.addCredits', 'Add credits to your account balance') + (risky ? '' : t('description.orVerifyCard', ' or verify your card information by adding a payment method')) + '.'}</p>}
      {risky && <Balance loading={loading} profile={profile} onMakePayment={onMakePayment} emailVerified={emailVerified} />}

      <h3>{t('title.addPaymentMethod', 'Add Payment Method')}</h3>
      <Card
        title={t('title.paymentMethod', 'Payment Method')}
        style={cardStyle}
        extra={
          <>
            <Button
              style={{ borderRadius: '4px' }}
              type='primary'
              title={t('title.addUpdatePaymentMethod', 'Add or udpate payment method')}
              className='add-payment-method'
              loading={loading}
              disabled={loading || risky || !account_status}
              onClick={onConnectPaymentMethod}
            >{hasPaymentMethodOrSource ? t('action.update', 'Update') : t('title.connect', 'Connect')}</Button>
            {(balance >= 50 || isSupport) && <Popconfirm
              title={t('title.areYouSure', 'Are you sure?')}
              okText={t('action.yes', 'Yes')}
              cancelText={t('action.no', 'No')}
              onConfirm={onRemovePaymentMethod}
            >
              <Button
                type='text'
                size='small'
                title={t('title.removePaymentMethod', 'Remove payment method')}
                loading={loading}
                disabled={loading || !hasPaymentMethodOrSource}
              >{t('action.remove', 'Remove')}</Button>
            </Popconfirm>}
          </>
        }>

        {hasPaymentMethodOrSource ? (
          <>
            {last4 ? (
              <>**** **** **** {last4}</>
            ) : (
              <>
                {bank_name}<br/>
                {routing_number}<br/>
                {account_number}
              </>
            )}
          </>
        ) : (
          <>
            {t('description.noPaymantMethodAssociated', 'Currently, there is no payment method associated with your account')}<Trans i18nKey='dot'>.</Trans>
            <br/><br/>
            <Alert message={<>
              <Trans i18nKey='description.paymentsBackedBy'>
                Payments are backed by
                <a href='https://stripe.com' target='_blank' rel='noopener noreferrer'> Stripe</a>. It has the most stringent level of certification available in the payments industry (
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='http://www.visa.com/splisting/searchGrsp.do?companyNameCriteria=stripe'
                >PCI Service Provider Level 1</a>).
              </Trans>
            </>} type='info' showIcon/>
          </>
        )}
      </Card>
      <br/><br/>

      <Card
        title={<>{t('title.billingInformation', 'Billing Information')} ({t('description.billingInformation', 'for invoices')})</>}
        style={cardStyle}
      >
        <Form
          form={form}
          name='billing_information'
          initialValues={{ address, name, description }}
        >
          <Form.Item title={t('title.name', 'Name')} name='name'>
            <Input placeholder={t('description.legalName', 'Legal name, tax ID, VAT, etc.')} title={t('description.legalName', 'Legal name, tax ID, VAT, etc.')}/>
          </Form.Item>
          <Form.Item title={t('title.country', 'Country')} name={['address', 'country']}>
            <Select showSearch optionFilterProp='children' placeholder={t('title.country', 'Country')}>
              {regions.map(r => <Option key={r.value} value={r.value}>{r.name} ({r.value})</Option>)}
            </Select>
          </Form.Item>
          <Form.Item title={t('title.address1', 'Address 1')} name={['address', 'line1']}>
            <Input placeholder={t('title.address1')}/>
          </Form.Item>
          <Form.Item title={t('title.address2', 'Address 2')} name={['address', 'line2']}>
            <Input placeholder={t('title.address2')}/>
          </Form.Item>
          <Form.Item title={t('title.city', 'City')} name={['address', 'city']}>
            <Input placeholder={t('title.city', 'City')}/>
          </Form.Item>
          <Form.Item title={t('title.postalCode', 'Postal Code')} name={['address', 'postal_code']}>
            <Input placeholder={t('title.postalCode', 'Postal Code')}/>
          </Form.Item>
          <Form.Item title={t('title.state', 'State')} name={['address', 'state']}>
            <Input placeholder={t('title.state', 'State')}/>
          </Form.Item>
          <Form.Item>
            <Button
              style={{ borderRadius: '4px' }}
              title={t('title.save', 'Save')}
              type='primary'
              className='save-billing-info'
              loading={loading}
              onClick={onUpdateBillingInfo}>{t('title.save', 'Save')}</Button>
          </Form.Item>
        </Form>
      </Card>
      <br/><br/>
      <p className='help-p1'>
        * <Trans i18nKey='description.billingInformationHint'>This information will be displayed in future invoices. You might want to add a PO number, Tax ID, or VAT along with your company name</Trans><Trans i18nKey='dot'>.</Trans>
      </p>
    </>
  );
}

BillingInformation.propTypes = {
  location: PropTypes.object.isRequired,
  authForwarded: PropTypes.object.isRequired,
};
