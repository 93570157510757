import React from 'react';

import GoogleSearchBase from './Base';

export default function GoogleSearchNews() {
  return (
    <GoogleSearchBase
      isGoogleLocationSelect
      isGoogleRangeSelect
      title='Google Search News Scraper'
      subTitle='Extracts news'
      serviceName='google_search_news_service'
      queriesPlaceholder='Bitcoin&#10;Business News&#10;Tech News'
      apiTag='Google/paths/~1google-search-news/get'
    />
  );
}
