import React from 'react';

import SimpleService from './Common/SimpleService';

export default function InstagramProfilesScraper() {
  return (
    <SimpleService
      limitType={null}
      unitName='page'
      title='Instagram Profiles Scraper'
      subTitle='Extracts data from a list of pages'
      queriesLabel='URLs to IG pages, profile usernames'
      serviceName='instagram_profiles'
      queriesPlaceholder='realdonaldtrump&#10;https://www.instagram.com/realdonaldtrump'
      apiTag='Others/paths/~1instagram~1profiles/get'
    />
  );
}
