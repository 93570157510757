import React from 'react';
import PropTypes from 'prop-types';
import { Form, InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';

import history from '../../../utils/history';
import BaseService from '../Common/BaseService';
import CustomQueries from '../Common/Selects/CustomQueries';
import EnrichmentsItem from '../Common/EnrichmentsItem';
import UULEInput from '../Common/Google/UULEInput';
import LanguageSelect from '../Common/Google/LanguageSelect';
import RegionSelect from '../Common/Google/RegionSelect';
import InfoTooltip from '../../../shared/Components/InfoTooltip';
import GoogleDateRangeSelectt from '../Common/Google/DateRangeSelect';
import GoogleLocationSelect from '../Common/Google/LocationSelect';

const FORM_STYLE = { maxWidth: 800 };

export default function GoogleSearchBase({ title = 'Google Search scraper', subTitle, serviceName = 'google_search_service',
  queriesLabel = 'Queries to search', queriesPlaceholder = '', isPerQueryLimit = null, apiTag,
  isGoogleLocationSelect = null, isGoogleRangeSelect = null, unitName = 'search', learnMoreUrl, videoTutorialLink, tutorialLink, maxLimitPagesPerQuery,
  showLanguageAndRegion = true, supportedEnrichmentServices, showUule = null,
}) {
  const { hl: urlLanguage } = queryString.parse(history.location.search);
  const localEnrichmentsKey = `${serviceName}_enrichments`;
  const taskExtraDefaultParams = {
    region: 'US',
    language: urlLanguage ? urlLanguage : (localStorage.getItem('language') || 'en'),
    enrichments: localStorage.getItem(localEnrichmentsKey) ? JSON.parse(localStorage.getItem(localEnrichmentsKey)) : [],
    uule: '',
    limit_per_query: isPerQueryLimit ? 100 : 1,
  };

  const { t } = useTranslation();
  const localeQueriesLabel = queriesLabel ? t(`title.${queriesLabel}`, queriesLabel) : '';

  return (
    <BaseService
      title={title}
      subTitle={subTitle}
      serviceName={serviceName}
      unitName={unitName}
      learnMoreUrl={learnMoreUrl}
      videoTutorialLink={videoTutorialLink}
      tutorialLink={tutorialLink}
      apiTag={apiTag}
      taskExtraDefaultParams={taskExtraDefaultParams}
      FormBody={({ task, updateTask }) => {
        const { queries, language, region, uule, tbs, enrichments, limit_per_query, input_file: inputFile, settings } = task;

        function onSetLanguage(value) {
          updateTask({ language: value });
          localStorage.setItem('language', value);
        }

        function onEnrichmentChange(enrichments) {
          localStorage.setItem(localEnrichmentsKey, JSON.stringify(enrichments));
          updateTask({ enrichments });
        }

        function onFileUploaded(input_file, enrich, output_extension) {
          updateTask({ input_file, enrich, queries: '', settings: { ...settings, output_extension } });
        }

        return <>
          <Form.Item
            required
            label={localeQueriesLabel}
            className='formItem'
            style={FORM_STYLE}
          >
            <CustomQueries
              value={queries}
              onChange={(v) => updateTask({ queries: v })}
              inputFile={inputFile}
              onFileUploaded={onFileUploaded}
              placeholder={queriesPlaceholder}
            />
          </Form.Item><br/>

          <Form.Item label={isPerQueryLimit ? t('title.perQueryLimit', 'Limit per one query') : t('title.pagesPerQueryLimit', 'Pages limit per one query')} className='formItem'>
            <InputNumber
              min={1}
              max={isPerQueryLimit ? null : maxLimitPagesPerQuery}
              step={isPerQueryLimit ? 100 : 1}
              value={limit_per_query}
              onChange={(v) => updateTask({ limit_per_query: parseInt(v) || 1 })}
            />
            <InfoTooltip title={isPerQueryLimit ? t('description.perQueryLimit', 'Parameter specifies the limit of results per one query') : t('description.pagesPerQueryLimit', 'Parameter specifies the limit of pages per one query')} />
          </Form.Item><br/>

          {isGoogleRangeSelect && <>
            <Form.Item label='Date range' className='formItem'>
              <GoogleDateRangeSelectt value={tbs} onChange={updateTask} />
            </Form.Item>
            <br/>
          </>}

          {isGoogleLocationSelect ? (
            <Form.Item label={t('title.country', 'Country')} className='formItem'>
              <GoogleLocationSelect value={region} onChange={updateTask} />
            </Form.Item>
          ) : showLanguageAndRegion && (
            <>
              <Form.Item label={t('title.language', 'Language')} className='formItem'>
                <LanguageSelect onChange={onSetLanguage} value={language}/>
              </Form.Item><br/>

              <Form.Item label={t('title.Region', 'Region')} className='formItem'>
                <RegionSelect allowClear={false} value={region} onChange={(v) => updateTask({ region: v })} />
              </Form.Item><br/>

              {showUule &&
                <><Form.Item label='UULE' className='formItem'>
                  <UULEInput allowClear={false} value={uule} onChange={(v) => updateTask({ uule: v })} />
                </Form.Item><br/></>
              }
            </>
          )}

          {supportedEnrichmentServices &&
            <EnrichmentsItem
              serviceName={serviceName}
              value={enrichments}
              onChange={onEnrichmentChange}
              supportedEnrichmentServices={supportedEnrichmentServices}
              title={title}
              learnMoreUrl={learnMoreUrl}
            />
          }
          <br/>
        </>;
      }}
    />
  );
}

GoogleSearchBase.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  serviceName: PropTypes.string,
  queriesLabel: PropTypes.string,
  queriesPlaceholder: PropTypes.string,
  isPerQueryLimit: PropTypes.bool,
  isGoogleLocationSelect: PropTypes.bool,
  isGoogleRangeSelect: PropTypes.bool,
  unitName: PropTypes.string,
  learnMoreUrl: PropTypes.string,
  videoTutorialLink: PropTypes.string,
  tutorialLink: PropTypes.string,
  maxLimitPagesPerQuery: PropTypes.number,
  showLanguageAndRegion: PropTypes.bool,
  supportedEnrichmentServices: PropTypes.array,
  apiTag: PropTypes.string,
  showUule: PropTypes.bool,
};
