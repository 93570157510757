import React from 'react';
import { Form, Select, InputNumber } from 'antd';

import SimpleService from './Common/SimpleService';
import CustomItemsSelect from './Common/Selects/CustomItemsSelect';
import { services } from '../../shared/data/services';

const { Option } = Select;
const REGION_DEFAULT_LOCATIONS = { US: 'New York, NY', AU: 'Sydney, NSW', CA: 'Toronto, ON' };
const DEFAULT_REGION = 'US';

export default function YellowPagesSearchScraper() {
  return (
    <SimpleService
      unitName='business'
      title={services.yellowpages_service.name}
      subTitle={services.yellowpages_service.description}
      serviceName='yellowpages_service'
      queriesLabel='Categories'
      queriesPlaceholder='bars&#10;restaurants&#10;dentists'
      learnMoreUrl='https://outscraper.com/yellowpages-scraper/'
      apiTag='/Others/paths/~1yellowpages-search/get'
      supportedEnrichmentServices={[]}
      allowFileUpload={false}
      limitType={null}
      defaultParams={{
        locations: REGION_DEFAULT_LOCATIONS[DEFAULT_REGION],
        region: DEFAULT_REGION,
        limit_per_query: 100,
      }}
      ExtraItems={({ updateTask, task }) => {
        return <>
          <Form.Item required label='Locations' style={{ maxWidth: 800 }}>
            <CustomItemsSelect
              autoFocus
              value={task.locations}
              onChange={(v) => updateTask({ locations: v })}
              placeholder='New York, NY&#10;Los Angeles, CA'
            />
          </Form.Item>

          <Form.Item className='formItem'>
            <Form.Item required label='Region' className='inlineFormItem'>
              <Select value={task.region} style={{ width: 230 }} onChange={(v) => updateTask({ region: v, locations: REGION_DEFAULT_LOCATIONS[v] })}>
                <Option key='US' value='US'>US (yellowpages.com)</Option>
                <Option key='AU' value='AU'>AU (yellowpages.com.au)</Option>
                <Option key='CA' value='CA'>CA (yellowpages.ca)</Option>
                <Option key='BE' value='BE'>BE (goldenpages.be)</Option>
              </Select>
            </Form.Item>

            <Form.Item label='Limit per one category' className='inlineFormItem'>
              <InputNumber
                min={1}
                max={100000}
                step={100}
                value={task.limit_per_query}
                onChange={(v) => updateTask({ limit_per_query: parseInt(v) || 1 })}
              />
            </Form.Item>
          </Form.Item><br/>
        </>;
      }}
    />
  );
}
