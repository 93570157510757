import React from 'react';
import PropTypes from 'prop-types';
import { Form, Select } from 'antd';

import { services } from '../../shared/data/services';
import PremiumFeatureTooltip from '../../shared/Components/PremiumFeatureTooltip';
import SimpleService from './Common/SimpleService';
import PreferredContactsItem from './Common/PreferredContactsItem';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

export default function DomainsExtractor() {
  const { t } = useTranslation();

  return (
    <SimpleService
      limitType={null}
      unitName='domain'
      tourSteps={[
        {
          target: () => document.querySelector('.queries-input'),
          title: t('tour.queriesDomainInput', 'Enter a list of domains or websites you want to get contacts from.'),
        }
      ]}
      title='Emails & Contacts Scraper'
      subTitle='Finds email addresses, social links, phones, and other contacts from domains'
      serviceName='domains_service'
      queriesPlaceholder='company.com&#10;company2.com'
      queriesLabel='Domains or URLs'
      videoTutorialLink='https://www.youtube.com/embed/_BAlDfZFVPA'
      learnMoreUrl='https://outscraper.com/emails-scraper'
      tutorialLink='https://outscraper.com/how-to-scrape-emails'
      apiTag='Email-Related/paths/~1emails-and-contacts/get'
      enrichmentFields={services.domains_service.input_fields}
      supportedEnrichmentServices={[]}
      exludedEnrichmentServices={['contacts_finder_service']}
      ExtraItems={({ updateTask, task, isPaidUser }) => <ExtraItems updateTask={updateTask} task={task} isPaidUser={isPaidUser} />}
    />
  );
}

export function ExtraItems({ updateTask, task, isPaidUser }) {
  const { t } = useTranslation();

  const { general_emails } = task;
  return <>
    <PreferredContactsItem value={task} onChange={updateTask} isPaidUser={!isPaidUser === false} />
    <Form.Item label={t('title.type', 'Type')}>
      <PremiumFeatureTooltip isPaidUser={!isPaidUser === false}>
        <Select
          placeholder='All'
          allowClear
          style={{ width: 300 }}
          value={general_emails}
          disabled={!isPaidUser}
          onChange={(v) => updateTask({ general_emails: v })}
        >
          <Option key='true' value={true}>General (info@, support@, etc.)</Option>
          <Option key='false' value={false}>Not general (paul@, john@, etc.)</Option>
        </Select>
      </PremiumFeatureTooltip>
    </Form.Item>
  </>;
}

ExtraItems.propTypes = {
  updateTask: PropTypes.func.isRequired,
  task: PropTypes.object.isRequired,
  isPaidUser: PropTypes.bool,
};
