import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';

import InfoTooltip from '../../../shared/Components/InfoTooltip';
import TagsSelect from './Selects/TagsSelect';

export default function TagsFormItem({ task, onChange }) {
  const { tags } = task;
  const { t } = useTranslation();

  return (
    <Form.Item className='inlineFormItem' label={t('title.taskTags', 'Task tags')}>
      <span className='nobreak'>
        <TagsSelect value={tags} onChange={(v) => onChange({ ...task, tags: v })}/>
        <InfoTooltip title={t('description.taskTags', 'Label the task to find it later on the tasks page')}/>
      </span>
    </Form.Item>
  );
}

TagsFormItem.propTypes = {
  task: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

// export default React.memo(TagsFormItem, (prevProps, nextProps) => {
//   const { tags: prevPropsTags } = prevProps.task;
//   const { tags: nextPropsTags } = nextProps.task;

//   return prevPropsTags.length === nextPropsTags.length && prevPropsTags.every((el, i) => el === nextPropsTags[i]);
// });
