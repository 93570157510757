import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, notification } from 'antd';
import { useTranslation, Trans } from 'react-i18next';

import GA from '../analytics/ga';
import apiProfile from '../api/apiProfile';
import LoginSignupForm from './Common/LoginSignupForm';
import EmailFormItem from './Common/EmailFormItem';
import PasswordFormItem from './Common/PasswordFormItem';

export default function Signup({ auth, history, location }) {
  const [checkEmail, setCheckEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  if (window.top !== window.self) {
    window.top.location = window.location;
  }

  async function onFinish(payload){
    setLoading(true);

    const referrer = localStorage.getItem('referrer');
    if (referrer) payload.referrer = referrer;

    const source = localStorage.getItem('source');
    if (source) payload.source = source;

    GA.track('button', 'Signup', 'Intent');

    try {
      const { data } = await apiProfile.createProfile(payload);
      const { access_token } = data;

      notification.success({ message: t('title.checkInbox', 'Check Your Inbox') });
      GA.track('button', 'Signup success', source || 'No source');

      setCheckEmail(payload['email']);
      auth.setSession(access_token, true);
    } catch (error) {
      notification.error({ message: error.message });
    } finally {
      setLoading(false);
    }
  }

  function toLogin(){
    history.push('login');
  }

  return <LoginSignupForm
    captcha
    title={t('title.welcome', 'Welcome to Outscraper')}
    subTitle={t('description.signUp', 'Sign Up to Outscraper Platform')}
    loading={loading}
    location={location}
    onFinish={onFinish}
    checkEmail={checkEmail}
    AfterButtonComponent={() => <><Trans i18nKey='description.alreadyHaveAccount'>Already have an account?</Trans> <Button type='link' style={{ marginLeft: -15 }} onClick={toLogin}><Trans i18nKey='title.logIn'>Log In</Trans></Button></>}
  >
    <EmailFormItem />
    <PasswordFormItem />
  </LoginSignupForm>;
}
Signup.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};
