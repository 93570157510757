import React from 'react';
import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import SimpleService from './Common/SimpleService';

const { Option } = Select;

export default function YouTubeCommentsScraper() {
  const { t } = useTranslation();

  return (
    <SimpleService
      unitName='page'
      title='YouTube Comments Scraper'
      subTitle='Extracts comments'
      queriesLabel='Video links or video IDs'
      apiTag='YouTube/paths/~1youtube-comments/get'
      serviceName='youtube_comments_service'
      queriesPlaceholder='ph5pHgklaZ0&#10;https://www.youtube.com/watch?v=ph5pHgklaZ0'
      learnMoreUrl='https://outscraper.com/youtube-comments-scraper'
      ExtraItems={({ updateTask, task }) => {
        const { sort = '' } = task;

        return <>
          <Form.Item label={t('title.sorting', 'Sorting')}>
            <Select value={sort} style={{ width: 150 }} onChange={(v) => updateTask({ sort: v })}>
              <Option key='top' value=''>Top comments</Option>
              <Option key='newest' value='newest'>Newest first</Option>
            </Select>
          </Form.Item>
        </>;
      }}
    />
  );
}
