import React from 'react';
import ReactDOM from 'react-dom';
import { Spin } from 'antd';

import './index.css';
import './config';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Mixpanel from './analytics/mixpanel';
import GA from './analytics/ga';
import { BrowserRouter as Router } from 'react-router-dom';
import 'antd/dist/reset.css';
import './i18n';

ReactDOM.render(<Router><React.Suspense fallback={<div className='center'><Spin /></div>}><App/></React.Suspense></Router>, document.getElementById('root'));

serviceWorker.unregister();
Mixpanel.init();
GA.init();
