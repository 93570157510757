import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'antd';
import { useTranslation } from 'react-i18next';

const { Panel } = Collapse;
const defaultHeader = 'Advanced parameters';

export default function AdvancedParameters({ children, header }) {
  const { t } = useTranslation();

  if (!header) {
    header = t(`title.${defaultHeader}`, defaultHeader);
  }

  return <>
    <Collapse ghost style={{ maxWidth: 800 }}>
      <Panel forceRender header={header} key='advancedParameters'><div style={{ marginLeft: 30 }}>{children}</div></Panel>
    </Collapse>
    <br/>
  </>;
}

AdvancedParameters.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
};
