import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { regions } from '../../../shared/data/regions';
import InfoTooltip from '../../../shared/Components/InfoTooltip';

const { Option } = Select;

export default function CountrySelect({ value, onChange, tooltip, allowClear = true }) {
  const { t } = useTranslation();

  return <>
    <Select
      allowClear={allowClear}
      showSearch
      style={{ width: 200 }}
      value={value}
      optionFilterProp='children'
      onChange={onChange}
      placeholder={t('action.selectCountry', 'Select country')}
    >
      {regions.map(r => <Option key={r.value} value={r.value}>{r.name} ({r.value})</Option>)}
    </Select>
    {tooltip && <InfoTooltip title={tooltip}/>}
  </>;
}

CountrySelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  tooltip: PropTypes.bool,
  allowClear: PropTypes.bool,
};
