import React from 'react';

import SimpleService from './Common/SimpleService';

export default function ShopeeReviews() {
  return (
    <SimpleService
      unitName='review'
      title='Shopee Reviews Scraper'
      startTaskButtonTitle='Scrape Reviews'
      subTitle='Extracts reviews from a list of products'
      serviceName='shopee_reviews'
      learnMoreUrl='https://outscraper.com/shopee-reviews-scraper/'
      queriesPlaceholder='https://shopee.ph/Apple-iPhone-14-Pro-(6.1-inch)-i.350609763.20351784876'
      defaultParams={{ sort: 0 }}
      sortingOptions={[
        { value: 0, text: 'All' },
        { value: 1, text: 'With comment' },
        { value: 3, text: 'With media' },
      ]}
    />
  );
}
