import React from 'react';
import { LockOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';

export default function PasswordFormItem() {
  return <Form.Item
    name='password'
    rules={[
      { required: true, message: 'Please enter your password' },
      { min: 8, message: 'Weak password' },
    ]}
    validateTrigger='onSubmit'
  >
    <Input.Password prefix={<LockOutlined />} placeholder='Password' />
  </Form.Item>;
}
