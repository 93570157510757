import React from 'react';

import { Form, Select } from 'antd';
import SimpleService from './Common/SimpleService';
import RegionSelect from './Common/Google/RegionSelect';

const { Option } = Select;

export default function GoogleTrends() {
  return (
    <SimpleService
      limitType={null}
      unitName='query'
      title='Google Trends Scraper'
      subTitle='Extracts data from a list of businesses'
      serviceName='google_trends'
      queriesPlaceholder='tesla&#10;tesla | toyota'
      learnMoreUrl='https://outscraper.com/google-trends-scraper'
      defaultParams={{
        resolution: 'CITY',
        timeframe: 'now 1-d',
        geo: 'US',
      }}
      ExtraItems={({ updateTask, task, geo }) => {
        const { resolution, timeframe } = task;
        return <>
          <Form.Item label='Geo'>
            <RegionSelect value={geo} onChange={(v) => updateTask({ geo: v })}/>
          </Form.Item>

          <Form.Item label='Timeframe'>
            <Select value={timeframe} style={{ width: 150 }} onChange={(v) => updateTask({ resolution: v })}>
              <Option key='now 1-H' value='now 1-H'>Past hour</Option>
              <Option key='now 4-H' value='now 4-H'>Past 4 hours</Option>
              <Option key='now 1-d' value='now 1-d'>Past day</Option>
              <Option key='now 7-d' value='now 7-d'>Past 7 days</Option>
              <Option key='today 1-m' value='today 1-m'>Past 30 days</Option>
              <Option key='today 3-m' value='today 3-m'>Past 90 days</Option>
              <Option key='today 12-m' value='today 12-m'>Past 12 months</Option>
              <Option key='today 5-y' value='today 5-y'>Past 5 years</Option>
              <Option key='all' value='all'>2004 - present</Option>
            </Select>
          </Form.Item>

          <Form.Item label='Resolution'>
            <Select value={resolution} style={{ width: 150 }} onChange={(v) => updateTask({ resolution: v })}>
              <Option key='CITY' value='CITY'>CITY</Option>
              <Option key='COUNTRY' value='COUNTRY'>COUNTRY</Option>
              <Option key='DMA' value='DMA'>DMA</Option>
              <Option key='REGION' value='REGION'>REGION</Option>
            </Select>
          </Form.Item>
        </>;
      }}
    />
  );
}
