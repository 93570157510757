import React from 'react';
import PropTypes from 'prop-types';
import { Form, Select } from 'antd';

import PremiumFeatureTooltip from '../../../shared/Components/PremiumFeatureTooltip';

const { Option } = Select;

export default function PreferredContactsItem({ value, onChange, isPaidUser }) {
  const { preferred_contacts } = value;

  return <>
    <Form.Item label='Preferred contacts' className='preferred-contacts'>
      <PremiumFeatureTooltip isPaidUser={!isPaidUser === false}>
        <Select
          disabled={!isPaidUser}
          allowClear
          placeholder='All'
          mode='tags'
          maxTagCount={2}
          tokenSeparators={[',']}
          value={preferred_contacts}
          style={{ width: 300 }}
          onChange={(v) => onChange({ preferred_contacts: v })}
        >
          <Option key='decision makers' value='decision makers'>Decision makers</Option>
          <Option key='influencers' value='influencers'>Influencers</Option>
          <Option key='procurement/purchasing' value='procurement/purchasing'>Procurement/purchasing</Option>
          <Option key='technical' value='technical'>Technical</Option>
          <Option key='finance' value='finance'>Finance</Option>
          <Option key='operations' value='operations'>Operations</Option>
          <Option key='marketing' value='marketing'>Marketing</Option>
          <Option key='sales' value='sales'>Sales</Option>
          <Option key='maintenance' value='maintenance'>Maintenance</Option>
          <Option key='human resources' value='human resources'>Human resources</Option>
          <Option key='legal and compliance' value='legal and compliance'>Legal and compliance</Option>
          <Option key='supply chain/logistics' value='supply chain/logistics'>Supply chain/logistics</Option>
          <Option key='education/training' value='education/training'>Education/training</Option>
        </Select>
      </PremiumFeatureTooltip>
    </Form.Item>
  </>;
}

PreferredContactsItem.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  isPaidUser: PropTypes.bool,
};
