import React from 'react';

import GoogleSearchBase from './Base';

export default function GoogleSearchEvents() {
  return (
    <GoogleSearchBase
      isGoogleLocationSelect
      title='Google Search Events Scraper'
      subTitle='Extracts search results'
      serviceName='google_search_events_service'
      queriesPlaceholder='beer festivals minnesota'
    />
  );
}
