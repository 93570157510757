import React from 'react';

import SimpleService from './Common/SimpleService';

export default function PlacesByDomainsSearch() {
  return (
    <SimpleService
      unitName='domain'
      defaultLimit={1}
      title='Google Maps Search by Domains'
      subTitle='Finds places by domains'
      serviceName='google_places_domain_search_service'
      queriesPlaceholder='dominopark.com&#10;https://www.esbnyc.com/'
      queriesLabel='Domains or websites'
      apiTag='Google/paths/~1google-places-by-domain/get'
      supportedEnrichmentServices={[]}
      serviceOntologies={['domain', 'phone', 'address']}
    />
  );
}
