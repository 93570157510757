import React from 'react';

import SimpleService from './Common/SimpleService';

export default function OnlyfansProfilesScraper() {
  return (
    <SimpleService
      limitType={null}
      unitName='page'
      title='OnlyFans Profiles Scraper'
      subTitle='Extracts data from a list of profiles'
      learnMoreUrl='https://outscraper.com/onlyfans-scraper'
      queriesLabel='Usernames, pages URLs'
      serviceName='onlyfans_profiles_service'
      queriesPlaceholder='aaroncarter&#10;https://onlyfans.com/aaroncarter'
    />
  );
}
