import React from 'react';

import GoogleSearchBase from './Base';

export default function GoogleSearch() {
  return (
    <GoogleSearchBase
      showUule
      title='Google Search Scraper'
      subTitle='Extracts search results'
      serviceName='google_search_service_v3'
      queriesPlaceholder='Iphone&#10;Bitcoin&#10;Avia tickets'
      videoTutorialLink='https://www.youtube.com/embed/SxGa_lXrCIU'
      learnMoreUrl='https://outscraper.com/google-search-scraper'
      tutorialLink='https://outscraper.com/scraping-google-search-results'
      apiTag='Google/paths/~1google-search-v3/get'
      maxLimitPagesPerQuery={30}
      supportedEnrichmentServices={['domains_service', 'emails_validator_service', 'disposable_email_checker', 'trustpilot_service']}
    />
  );
}
