import React from 'react';
import PropTypes from 'prop-types';
import { Form, Checkbox, InputNumber, DatePicker } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import Moment from 'react-moment';
import dayjs from 'dayjs';

import ReviewsSortSelect from '../Selects/ReviewsSortSelect';
import InfoTooltip from '../../../../shared/Components/InfoTooltip';
import PremiumFeatureTooltip from '../../../../shared/Components/PremiumFeatureTooltip';

const inlineFormStyle = { display: 'inline-block', paddingRight: 28 };
const FORMAT = 'YYYY/MM/DD HH:mm:ss';

export default function ReviewsSortingFields({ task, onChange, isPaidUser }) {
  const { start, cutoff, relativeCutoff, cutoffRating, sort } = task;
  const useStartDate = !!start;
  const useCutoffDate = !!cutoff;
  const useCutoffRating = cutoffRating !== null;
  const { t } = useTranslation();

  function disabledStartDate(current) {
    if (current > dayjs()) return true;

    if (current < dayjs().subtract(12, 'months')) return true;

    if (useCutoffDate && cutoff) {
      if (current <= cutoff) return true;
    }

    return false;
  }

  function disabledCutoffDate(current) {
    let result = current > dayjs();

    if (useStartDate && start) {
      if (current >= start) return true;
    }

    return result;
  }

  function onSetUseStartDate() {
    onChange({ start: start ? null : dayjs() });
  }

  function onSetUseCutoffDate() {
    onChange({ cutoff: cutoff ? null : dayjs().startOf('month') });
  }

  function onSetUseRelativeCutoffDate() {
    onChange({ relativeCutoff: relativeCutoff ? null : dayjs().unix() - cutoff.unix() });
  }

  function onSetUseCutoffRating() {
    onChange({ cutoffRating: cutoffRating ? null : 3 });
  }

  function onSetCutoffDate(value) {
    onChange({ cutoff: value });
  }

  function onSetStartDate(value) {
    onChange({ start: value });
  }

  function onSetCutoffRating(value) {
    onChange({ cutoffRating: parseInt(value) });
  }

  function onSetSort(value) {
    onChange({
      sort: value,
      cutoff: value !== 'newest' ? null : cutoff,
      cutoffRating: ['highest_rating', 'lowest_rating'].includes(value) ? cutoffRating : null,
    });
  }

  return <>
    <Form.Item
      label={t('title.sorting', 'Sorting')}
      className='sorting-select'
      style={inlineFormStyle}
    >
      <span className='nobreak'>
        <ReviewsSortSelect onChange={onSetSort} value={sort}/>
        <InfoTooltip title={t('description.sorting', 'Parameter specifies one of the sorting types')}/>
      </span>
    </Form.Item>

    {sort === 'newest' && <>
      <Form.Item>
        <Checkbox checked={useCutoffDate} onChange={() => onSetUseCutoffDate()}>
          <Trans i18nKey='action.gmr.newestFrom'>From (oldest review)</Trans>:
        </Checkbox>
        {useCutoffDate && <>
          {relativeCutoff ? <>
            <strong><Moment unix local fromNow>{dayjs().unix() - relativeCutoff}</Moment></strong>
          </> : <>
            <DatePicker
              showTime
              format={FORMAT}
              value={cutoff}
              onChange={onSetCutoffDate}
              disabledDate={disabledCutoffDate}
            />
          </>}
          &nbsp;&nbsp;&nbsp;
          <Checkbox checked={relativeCutoff} onChange={() => onSetUseRelativeCutoffDate()}>
            <Trans i18nKey='title.relative'>Relative</Trans>
          </Checkbox>
        </>}
      </Form.Item>

      <Form.Item>
        <PremiumFeatureTooltip isPaidUser={isPaidUser}>
          <Checkbox disabled={!isPaidUser} checked={useStartDate} onChange={() => onSetUseStartDate()}>
            <Trans i18nKey='action.gmr.newestTo'>To (newest review)</Trans>:
          </Checkbox>
        </PremiumFeatureTooltip>
        {useStartDate &&
          <DatePicker
            showTime
            format={FORMAT}
            value={start}
            onChange={onSetStartDate}
            disabledDate={disabledStartDate}
          />}
      </Form.Item>
    </>}

    {['highest_rating', 'lowest_rating'].includes(sort) &&
      <Form.Item>
        <Checkbox checked={useCutoffRating} onChange={() => onSetUseCutoffRating()}>
          {sort === 'highest_rating' ?
            <Trans i18nKey='action.gmr.ratingLess'>Do not extract reviews with rating less than</Trans>
            :
            <Trans i18nKey='action.gmr.ratingBigger'>Do not extract reviews with rating bigger than</Trans>
          }
        </Checkbox>
        {useCutoffRating && <InputNumber value={cutoffRating} onChange={onSetCutoffRating} min={1} max={5}/>}
      </Form.Item>
    }
  </>;
}

ReviewsSortingFields.propTypes = {
  task: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  isPaidUser: PropTypes.bool,
};
