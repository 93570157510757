import React from 'react';
import { MailOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';

export default function EmailFormItem() {
  return <Form.Item
    name='email'
    rules={[{ type: 'email', required: true, message: 'Please enter your email' }]}
    validateTrigger='onSubmit'
  >
    <Input prefix={<MailOutlined />} placeholder='Email' />
  </Form.Item>;
}
