import React from 'react';

import SimpleService from './Common/SimpleService';

export default function TiktokProfilesScraper() {
  return (
    <SimpleService
      limitType={null}
      unitName='page'
      title='TikTok Profiles Scraper'
      subTitle='Extracts data from a list of profiles'
      queriesLabel='Usernames, pages URLs'
      serviceName='tiktok_profiles'
      queriesPlaceholder='outscraper&#10;https://tiktok.com/@outscraper'
      apiTag='Others/paths/~1tiktok~1profiles/get'
    />
  );
}
