import React from 'react';

import SimpleService from './Common/SimpleService';

export default function ShopifyProductsScraper() {
  return (
    <SimpleService
      beta
      unitName='product'
      title='Shopify Products Scraper'
      subTitle='Extracts products from Shopify websites'
      serviceName='shopify_products'
      queriesLabel='Links to Shopify websites'
      queriesPlaceholder='https://rothys.com/collections/womens-shoes'
      learnMoreUrl='https://outscraper.com/shopify-scraper/'
    />
  );
}
