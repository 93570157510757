import React, { useState } from 'react';
import queryString from 'query-string';
import { notification, Form, Card, Button, Popconfirm, Select, InputNumber, Col, Row } from 'antd';
import { useTranslation, Trans } from 'react-i18next';

import Testimonials from '../../../../shared/Components/Testimonials';
import Mixpanel from '../../../../analytics/mixpanel';
import history from '../../../../utils/history';
import apiPayment from '../../../../api/apiPayment';

const { Option } = Select;
const { Meta } = Card;

const MIN_AMOUNT = 25;

export default function MakeCryptoPayment() {
  const { amount: queryAmount } = queryString.parse(history.location.search);
  const [currency, setCurrency] = useState('btc');
  const [amount, setAmount] = useState(Math.max(parseInt(queryAmount) || 100, MIN_AMOUNT));
  const [loading, setLoading] = useState(false);
  const formatedCurrency = currency.toLocaleUpperCase();
  const bonus = 4;
  const { t } = useTranslation();

  function handleSetAmount(value) {
    setAmount(parseInt(value) || MIN_AMOUNT);
  }

  async function createCryptoPayment() {
    setLoading(true);
    try {
      const { data } = await apiPayment.createCryptoPayment(amount, currency);
      const { payment_id } = data;
      history.push(`/profile/crypto-payment/${payment_id}`);
    } catch (error) {
      notification.error({ message: error.message });
    } finally {
      setLoading(false);
    }

    Mixpanel.track('Crypto payment intent', { currency });
  }

  return (
    <Row>
      <Col flex='auto'>
        <Card bordered={false} style={{ maxWidth: 450 }}>
          <Meta
            title={t('title.addCredits', 'Add Credits')}
            description={<Trans i18nKey='description.addCreditsCrypto'>Deposit funds to your account balance with cryptocurrency and receive a {{bonus}}% bonus from your payment.</Trans>}
          />
          <br/>
          <Form name='basic' layout='vertical'>
            <Form.Item label={t('title.currency', 'Currency')}>
              <Select style={{ width: 180 }} value={currency} onChange={setCurrency}>
                <Option value='btc'>BTC</Option>
                <Option value='eth'>ETH (Mainnet)</Option>
                <Option value='usdtbsc'>USDT (BSC)</Option>
                <Option value='usdttrc20'>USDT (TRC20)</Option>
                <Option value='usdtmatic'>USDT (POLYGON)</Option>
                <Option value='usdtsol'>USDT (SOL)</Option>
                <Option value='usdc'>USDC (ETH)</Option>
                <Option value='usdcbsc'>USDC (BSC)</Option>
                <Option value='usdcmatic'>USDC (POLYGON)</Option>
                <Option value='usdcsol'>USDC (SOL)</Option>
                <Option value='xmr'>Monero (XMR)</Option>
              </Select>
            </Form.Item>
            <Form.Item label={t('title.amount', 'Amount') + ' USD'}>
              <InputNumber
                formatter={value => `$ ${value}`}
                value={amount}
                min={MIN_AMOUNT}
                max={5000}
                step={10}
                onChange={handleSetAmount}
              />
            </Form.Item>
            <Form.Item>
              <Popconfirm
                title={<Trans i18nKey='description.addCreditsConfirm'>Pay ${{amount}} with {{formatedCurrency}}?</Trans>}
                okText={t('action.ok', 'Ok')}
                cancelText={t('action.cancel', 'Cancel')}
                onConfirm={createCryptoPayment}
              >
                <Button loading={loading} title={`Create ${formatedCurrency} address and pay`} type='primary'>
                  <Trans i18nKey='action.addCreditsCrypto'>Create {{formatedCurrency}} address and pay</Trans>
                </Button>
              </Popconfirm>
            </Form.Item>
          </Form>
        </Card>
      </Col>
      <Col offset='auto'><Testimonials/></Col>
    </Row>
  );
}
