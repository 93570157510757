import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

import InfoTooltip from '../../../../shared/Components/InfoTooltip';

const StyledDiv = styled.span`
  float: right;
`;

export default function PlainQueriesSwitch({ checked, onChange }) {
  function handleOnChange() {
    onChange(!checked);
  }

  return (
    <StyledDiv className='plain-queries'>
      <Switch size='small' checked={checked} onChange={handleOnChange}/> <Trans i18nKey='action.gm.plainQueriesTitle'>Plain queries</Trans>
      <InfoTooltip title={<Trans i18nKey='action.gm.plainQueries'>Plain queries (search keywords, place IDs, URLs, etc.)</Trans>}/>
    </StyledDiv>
  );
}

PlainQueriesSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};
