import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Layout, Menu, Avatar, Badge, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AmazonOutlined, FacebookOutlined, GoogleOutlined, GlobalOutlined, MailOutlined, PhoneOutlined, YoutubeOutlined,
  LinkedinOutlined, StarOutlined, EnvironmentOutlined, ShoppingOutlined, AppstoreOutlined, ClockCircleOutlined,
  StarFilled, ApiOutlined, TwitterOutlined, InstagramOutlined, AppleFilled } from '@ant-design/icons';
import { faDollarSign, faStar, faTasks, faUsers, faBuilding, faFileInvoiceDollar, faGraduationCap, faComments,
  faQuestion, faVideo, faTimesCircle, faPhone, faFileAlt, faTable, faCreditCard, faPlug, faBug, faUser, faMagnet,
  faLocationPin, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import config from 'react-global-configuration';
// eslint-disable-next-line no-undef
var md5 = require('md5');

import { companyColors } from './../../styles';
import history from '../../utils/history';
import { isCredibleUser } from '../../utils/utils';
import { checkIfNewService } from '../../shared/data/services';
import FeatureRequestModal from '../Others/FeatureRequestModal';

const menuStyle = { backgroundColor: companyColors.darkBlue };
const theme = 'dark';
const collapsedKey = 'collapsed';
const COLLAPSED_AFTER_WIDTH = 900;

export default function Sider({ auth }) {
  const location = useLocation();
  const [isHelpOpened, setIsHelpOpened] = useState(false);
  const [featureRequestOpen, setFeatureRequestOpen] = useState(false);
  const [prevWidth, setPrevWidth] = useState(window.innerWidth);
  const [collapsed, setCollapsed] = useState(prevWidth < COLLAPSED_AFTER_WIDTH || localStorage.getItem(collapsedKey) === 'true');
  const { profile = {}, authenticated = false } = auth;
  const { email, avatar, roles = [] } = profile;
  const isTenant = roles.includes('tenant');
  const expert = roles.includes('expert') || roles.includes('support');
  const admin = roles.includes('admin');
  const { pathname } = location;
  const selectedKeys = pathname === '/' ? 'googleMaps' : pathname.substring(1);

  const starredServices = localStorage.getItem('starredServices');
  const recentServices = localStorage.getItem('recentServices');
  const recentNewServices = localStorage.getItem('recentNewServices');
  const starredServicesArray = starredServices ? JSON.parse(starredServices) : [];
  const recentServicesArray = recentServices ? JSON.parse(recentServices) : [];
  const recentNewServicesArray = [...recentNewServices ? JSON.parse(recentNewServices) : [], window.location.pathname.split('/')[1]];

  const { t, i18n } = useTranslation();

  const handleResize = useCallback(() => {
    const windowWidth = window.innerWidth;

    if (windowWidth < COLLAPSED_AFTER_WIDTH && windowWidth < prevWidth) {
      setCollapsed(true);
    } else if (windowWidth > 1100 && windowWidth > prevWidth) {
      setCollapsed(false);
    }

    setPrevWidth(windowWidth);
  }, [prevWidth]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  function getItem(label, key, icon, children, type) {
    var translatedLabel = (icon || children || type) ? label : t(`title.${label}`, label);

    const formatedKey = key ? key.split('?')[0] : key;
    const badgeCount = getBadgeCount(formatedKey, children);
    const isNewService = checkIfNewService(formatedKey, recentNewServicesArray);

    if (badgeCount || isNewService) {
      translatedLabel = <Badge
        title={isNewService ? 'New service' : `${badgeCount} new servic(es)`}
        size='small'
        dot={isNewService || null}
        count={isNewService ? null : badgeCount}
        offset={[isNewService ? 6 : badgeCount > 9 ? 20 : 12, 0]}
        overflowCount={9}
      >
        <span style={{ color: 'rgba(255, 255, 255, 0.65)' }}>{translatedLabel}&nbsp;</span>&nbsp;
      </Badge>;
    }

    if (badgeCount && collapsed && key == 'services') {
      icon = <Badge
        title={`${badgeCount} new services(es)`}
        size='small'
        count={badgeCount}
        offset={[badgeCount > 9 ? 20 : 12, 8]}
        overflowCount={9}
      >{icon}</Badge>;
    }

    if (type === 'group' && icon) {
      translatedLabel = <><span style={{ marginRight: 8 }}>{icon}</span>{translatedLabel}&nbsp;</>;
    }

    return {
      key,
      icon,
      children,
      label: translatedLabel,
      type,
      title: translatedLabel,
    };
  }

  function getBadgeCount(key, children) {
    if (checkIfNewService(key, recentNewServicesArray)) return 1;

    var result = 0;
    if (Array.isArray(children)) {
      children.forEach(({ key, children }) => {
        result += getBadgeCount(key ? key.split('?')[0] : key, children);
      });
    }

    return result;
  }

  function onMenuClick({ key }) {
    if (key.startsWith('https://') || key.startsWith('http://')) window.open(key);
    else if (key === 'support') {
      onHelpClick();
    }
    else if (key === 'report') {
      setFeatureRequestOpen(!featureRequestOpen);
    } else if (key === 'logout') {
      handleLogout();
    } else {
      if (key.split('/').length === 1 && window.location.pathname.split('/').length > 2) {
        window.location.pathname = key;
      } else {
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has('l')) {
          const languageParam = `l=${i18n.language.split('-')[0]}`;
          if (key.includes('?')) {
            history.push(key + '&' + languageParam);
          } else {
            history.push({ pathname: key, search: languageParam });
          }
        } else {
          history.push(key);
        }
      }
    }
  }

  function onHelpClick() {
    if (isHelpOpened) {
      window.Chatra('closeChat');
    } else {
      window.Chatra('updateIntegrationData', { email });
      window.Chatra('openChat', true);
    }
    setIsHelpOpened(!isHelpOpened);
  }

  function handleLogout() {
    Modal.confirm({
      title: t('title.logOut', 'Log Out'),
      content: t('title.areYouSure', 'Are you sure?'),
      okText: t('action.yes', 'Yes'),
      okType: 'danger',
      cancelText: t('action.no', 'No'),
      onOk() {
        history.push('/logout');
      },
    });
  }

  function showUserName(email) {
    if (email) {
      return email.split('@')[0];
    } else {
      return email;
    }
  }

  function onSetCollapsed(value) {
    setCollapsed(value);

    if (value) {
      localStorage.setItem(collapsedKey, 'true');
    } else {
      localStorage.removeItem(collapsedKey);
    }
  }

  const items = [
    getItem(t('title.services', 'Services'), 'services', <AppstoreOutlined />, [
      getItem(t('title.byBrand', 'By Brand'), 'sources', null, [
        getItem('Amazon', 'source-amazon', <AmazonOutlined />, [
          getItem('Amazon Products Scraper', 'amazon-products?'),
          getItem('Amazon Reviews Scraper', 'amazon-reviews?'),
        ]),
        getItem('Apple', 'source-apple', <AppleFilled />, [
          getItem('AppStore Reviews Scraper', 'appstore-reviews?'),
        ]),
        getItem('Airbnb', 'source-airbnb', null, [
          getItem('Airbnb Reviews Scraper', 'airbnb-reviews?'),
        ]),
        getItem('Booking', 'source-booking', null, [
          getItem('Booking Search Scraper', 'booking-search?'),
          getItem('Booking Reviews Scraper', 'booking-reviews?'),
        ]),
        getItem('Capterra', 'source-capterra', null, [
          getItem('Capterra Reviews Scraper', 'capterra-reviews?'),
        ]),
        getItem('eBay', 'source-ebay', null, [
          getItem('eBay Listings Scraper', 'ebay-products?'),
        ]),
        getItem('Expedia', 'source-expedia', null, [
          getItem('Expedia Reviews Scraper', 'expedia-reviews?'),
        ]),
        getItem('Facebook', 'source-facebook', <FacebookOutlined />, [
          getItem('Facebook Pages Scraper', 'facebook-pages?'),
          getItem('Facebook Reviews Scraper', 'facebook-reviews?'),
        ]),
        getItem('Fastbackgroundcheck', 'source-fastbackgroundcheck', null, [
          getItem('Fastbackgroundcheck Addresses Scraper', 'fastbackgroundcheck-addresses?'),
        ]),
        getItem('GetApp', 'source-getapp', null, [
          getItem('GetApp Reviews Scraper', 'getapp-reviews?'),
        ]),
        getItem('Google', 'source-google', <GoogleOutlined />, [
          getItem('Google Maps Scraper', 'googleMaps?'),
          getItem('Google Maps Reviews Scraper', 'googleReviews?'),
          getItem('Google Search Scraper', 'google-search?'),
          getItem('Google Maps Search by Domains', 'google-places-domain-search?'),
          getItem('Google Maps Contributor Reviews Scraper', 'google-maps-contributor-reviews?'),
          getItem('Google Maps Photos Scraper', 'google-maps-photos?'),
          getItem(t('title.others', 'Others'), null, null, [
            getItem('Google Maps Traffic Scraper', 'googleTraffic?'),
            getItem('Google Maps Directory Places', 'google-directory-places?'),
            getItem('Google Search News Scraper', 'google-search-news?'),
            getItem('Google Search Images Scraper', 'google-search-images?'),
            getItem('Google Search Videos Scraper', 'google-search-videos?'),
            getItem('Google Search Events Scraper', 'google-search-events?'),
            getItem('Google Search Jobs', 'google-search-jobs?'),
            getItem('Google Search Careers', 'google-careers?'),
            getItem('Google Search Shopping Scraper', 'google-search-shopping?'),
            getItem('Google Trends Scraper', 'google-trends?'),
            getItem('Google Play Reviews Scraper', 'google-play-reviews?'),
            getItem('Google Shopping Reviews Scraper', 'google-shopping-reviews?'),
          ]),
        ]),
        getItem('Glassdoor', 'source-glassdoor', null, [
          getItem('Glassdoor Job Scraper', 'glassdoor-search?'),
          getItem('Glassdoor Reviews Scraper', 'glassdoor-reviews?'),
        ]),
        getItem('Home Depot', 'source-homedepot', null, [
          getItem('Home Depot Reviews Scraper', 'homedepot-reviews?'),
        ]),
        getItem('Indeed', 'source-indeed', null, [
          getItem('Indeed Job Scraper', 'indeed-search?'),
          getItem('Indeed Reviews Scraper', 'indeed-reviews?'),
        ]),
        getItem('Instagram', 'source-instagram', <InstagramOutlined />, [
          getItem('Instagram Profiles Scraper', 'instagram-profiles?'),
        ]),
        getItem('LI', 'source-Linkedin', <LinkedinOutlined />, [
          getItem('LI Companies Scraper', 'linkedin-companies?'),
          getItem('LI Profiles Scraper', 'linkedin-profiles?'),
          getItem('LI Posts Scraper', 'linkedin-posts?'),
        ]),
        getItem('Product Hunt', 'source-producthunt', null, [
          getItem('Product Hunt Reviews Scraper', 'producthunt-reviews?'),
        ]),
        getItem('Shopee', 'source-shopee', null, [
          getItem('Shopee Reviews Scraper', 'shopee-reviews?'),
        ]),
        getItem('Shopify', 'source-shopify', null, [
          getItem('Shopify Products Scraper', 'shopify-products?'),
        ]),
        getItem('Target', 'source-target', null, [
          getItem('Target Reviews Scraper', 'target-reviews?'),
        ]),
        getItem('TikTok', 'source-tiktok', null, [
          getItem('Profiles Scraper', 'tiktok-profiles?'),
        ]),
        getItem('Tripadvisor', 'source-tripadvisor', <FontAwesomeIcon icon={faBuilding}/>, [
          getItem('Tripadvisor Search', 'tripadvisor-search?'),
          getItem('Tripadvisor Reviews', 'tripadvisor-reviews?'),
        ]),
        getItem('Trustpilot', 'source-trustpilot', <FontAwesomeIcon icon={faStar}/>, [
          getItem('Trustpilot Scraper', 'trustpilot?'),
          getItem('Trustpilot Search Scraper', 'trustpilot-search?'),
          getItem('Trustpilot Reviews Scraper', 'trustpilot-reviews?'),
        ]),
        getItem('Twitter', 'source-twitter', <TwitterOutlined />, [
          getItem('Twitter Profiles Scraper', 'twitter-profiles?'),
        ]),
        getItem('Walmart', 'source-walmart', null, [
          getItem('Walmart Reviews Scraper', 'walmart-reviews?'),
        ]),
        getItem('Whitepages', 'source-whitepages', null, [
          getItem('Whitepages Phones Scraper', 'whitepages-phones?'),
          getItem('Whitepages Addresses Scraper', 'whitepages-addresses?'),
        ]),
        getItem('Youtube', 'source-youtube', <YoutubeOutlined />, [
          getItem('YouTube Channels Scraper', 'youtube-channels?'),
          getItem('YouTube Comments Scraper', 'youtube-comments?'),
          getItem('YouTube Search Scraper', 'youtube-search?'),
        ]),
        getItem('Yellow Pages', 'yellow-pages', null, [
          getItem('Yellow Pages Scraper', 'yellowpages?'),
        ]),
        ...(expert ? [
          getItem('Y.E.L.P', 'source-yelp', <FontAwesomeIcon style={faBuilding} />, [
            getItem('Y.E.L.P Reviews Scraper', 'yelp-reviews?'),
            getItem('Y.E.L.P Businesses Scraper', 'yelp?'),
          ]),
        ] : []),
        getItem('Zillow', 'source-zillow', <FontAwesomeIcon style={faBuilding} />, [
          getItem('Zillow Search Scraper', 'zillow-search?'),
        ]),
      ]),
      getItem(t('title.byType', 'By Type'), 'types', null, [
        getItem(t('title.poiRelated', 'Businesses & POI'), null, <EnvironmentOutlined />, [
          getItem('Google Maps Data Scraper', 'googleMaps'),
          getItem('Google Maps Search by Domains', 'google-places-domain-search'),
          getItem('Facebook Pages Scraper', 'facebook-pages'),
          getItem('Tripadvisor Search', 'tripadvisor-search'),
          getItem('Trustpilot Search Scraper', 'trustpilot-search'),
          getItem('Booking Search Scraper', 'booking-search'),
          getItem('Trustpilot Scraper', 'trustpilot'),
          getItem('LI Companies Scraper', 'linkedin-companies'),
          getItem('Whitepages Addresses Scraper', 'whitepages-addresses'),
          getItem('Fastbackgroundcheck Addresses Scraper', 'fastbackgroundcheck-addresses'),
          getItem('Yellow Pages Scraper', 'yellowpages'),
          getItem('Y.E.L.P Businesses Scraper', 'yelp'),
          getItem('Company Insights', 'company-insights'),
          getItem('Business Directory', 'https://targetron.com/b2b-local-leads-directory/'),
        ]),
        getItem(t('title.reviewsRelated', 'Reviews & Comments'), null, <StarOutlined />, [
          getItem('Amazon Reviews Scraper', 'amazon-reviews'),
          getItem('AppStore Reviews Scraper', 'appstore-reviews'),
          getItem('Airbnb Reviews Scraper', 'airbnb-reviews'),
          getItem('Booking Reviews Scraper', 'booking-reviews'),
          getItem('Capterra Reviews Scraper', 'capterra-reviews'),
          getItem('Expedia Reviews Scraper', 'expedia-reviews'),
          getItem('Facebook Reviews Scraper', 'facebook-reviews'),
          getItem('GetApp Reviews Scraper', 'getapp-reviews'),
          getItem('Glassdoor Reviews Scraper', 'glassdoor-reviews'),
          getItem('Google Maps Reviews Scraper', 'googleReviews'),
          getItem('Google Maps Contributor Reviews Scraper', 'google-maps-contributor-reviews'),
          getItem('Google Play Reviews Scraper', 'google-play-reviews'),
          getItem('Google Shopping Reviews Scraper', 'google-shopping-reviews'),
          getItem('Home Depot Reviews Scraper', 'homedepot-reviews'),
          getItem('Indeed Reviews Scraper', 'indeed-reviews'),
          getItem('Product Hunt Reviews Scraper', 'producthunt-reviews'),
          getItem('Shopee Reviews Scraper', 'shopee-reviews'),
          getItem('Target Reviews Scraper', 'target-reviews'),
          getItem('Tripadvisor Reviews Scraper', 'tripadvisor-reviews'),
          getItem('Trustpilot Reviews Scraper', 'trustpilot-reviews'),
          getItem('Walmart Reviews Scraper', 'walmart-reviews'),
          getItem('YouTube Comments Scraper', 'youtube-comments'),
          getItem('Y.E.L.P Reviews Scraper', 'yelp-reviews'),
        ]),
        getItem(t('title.productsRelated', 'Products & Listings'), null, <ShoppingOutlined />, [
          getItem('Amazon Products Scraper', 'amazon-products'),
          getItem('eBay Listings Scraper', 'ebay-products'),
          getItem('Google Search Shopping Scraper', 'google-search-shopping'),
          getItem('Shopify Products Scraper', 'shopify-products'),
        ]),
        getItem(t('title.emailRelated', 'Email Related'), null, <MailOutlined />, [
          getItem('Emails & Contacts Scraper', 'emails-scraper'),
          getItem('Contacts Finder', 'contacts-finder'),
          getItem(t('action.check', 'Check'), null, null, [
            getItem('Email Address Verifier', 'emails-validator'),
            getItem('Disposable Email Checker', 'disposable-email-checker'),
          ], 'group'),
          getItem('Email Addresses Finder', 'email-finder'),
        ]),
        getItem(t('title.phoneRelated', 'Phone Related'), null, <PhoneOutlined />, [
          getItem('Phone Numbers Enricher', 'phones-enricher'),
          getItem('Whitepages Phones Scraper', 'whitepages-phones'),
          getItem(t('action.check', 'Check'), null, null, [
            getItem('WhatsApp Checker', 'whatsapp-checker'),
            // getItem('iMessage Checker', 'imessage-checker'),
            // getItem('Telegram Checker', 'telegram-checker'),
            // getItem('WeChat Checker', 'wechat-checker'),
          ], 'group'),
          // getItem(t('action.send', 'Send'), null, null, [
          //   getItem('WhatsApp Sender', 'whatsapp-sender'),
          //   getItem('iMessage Sender', 'imessage-sender'),
          // ], 'group'),
        ]),
        getItem(t('title.domainRelated', 'Domain Related'), null, <GlobalOutlined />, [
          getItem('Emails & Contacts Scraper', 'emails-scraper?tab=domain'),
          getItem('Contacts Finder', 'contacts-finder?tab=domain'),
          getItem('Company Insights', 'company-insights?tab=domain'),
          getItem('Google Maps Search by Domains', 'google-places-domain-search?tab=domain'),
          getItem('Trustpilot Scraper', 'trustpilot?type=domain'),
        ]),
      ]),
      getItem(t('title.byUseCase', 'Use Cases'), 'usecases', null, [
        getItem(t('title.leadGeneration', 'Lead Generation'), null, <FontAwesomeIcon icon={faMagnet}/>, [
          getItem('Lead Scraper', 'googleMaps?tab=lead'),
          getItem('Contacts Finder', 'contacts-finder?tab=lead'),
          getItem('Domain Scraper', 'emails-scraper?tab=lead'),
          getItem('Email Addresses Finder', 'email-finder?tab=lead'),
          getItem('Email Address Verifier', 'emails-validator?tab=lead'),
          getItem('Disposable Email Checker', 'disposable-email-checker?tab=lead'),
        ]),
        getItem(t('title.reputationManagement', 'Reputation Management'), null, <StarFilled/>, [
          getItem('Amazon Reviews Scraper', 'amazon-reviews?tab=rep'),
          getItem('AppStore Reviews Scraper', 'appstore-reviews?tab=rep'),
          getItem('Booking Reviews Scraper', 'booking-reviews?tab=rep'),
          getItem('Capterra Reviews Scraper', 'capterra-reviews?tab=rep'),
          getItem('Facebook Reviews Scraper', 'facebook-reviews?tab=rep'),
          getItem('Google Maps Reviews Scraper', 'googleReviews?tab=rep'),
          getItem('Google Maps Contributor Reviews Scraper', 'google-maps-contributor-reviews?tab=rep'),
          getItem('Google Play Reviews Scraper', 'google-play-reviews?tab=rep'),
          getItem('Google Shopping Reviews Scraper', 'google-shopping-reviews?tab=rep'),
          getItem('Product Hunt Reviews Scraper', 'producthunt-reviews?tab=rep'),
          getItem('Shopee Reviews Scraper', 'shopee-reviews?tab=rep'),
          getItem('Tripadvisor Reviews Scraper', 'tripadvisor-reviews?tab=rep'),
          getItem('Trustpilot Reviews Scraper', 'trustpilot-reviews?tab=rep'),
          getItem('YouTube Comments Scraper', 'youtube-comments?tab=rep'),
          getItem('Y.E.L.P Reviews Scraper', 'yelp-reviews?tab=rep'),
        ]),
        getItem(t('title.locationIntelligence', 'Location Intelligence'), null, <FontAwesomeIcon icon={faLocationPin}/>, [
          getItem('Google Maps Data Scraper', 'googleMaps?tab=intel'),
          getItem('Google Maps Search by Domains', 'google-places-domain-search?tab=intel'),
          getItem('Y.E.L.P Businesses Scraper', 'yelp?tab=intel'),
          getItem('Yellow Pages Scraper', 'yellowpages?tab=intel'),
        ]),
      ]),
      getItem(t('title.otherServices', 'Other Services'), 'others', null, [
        getItem('Universal AI-Powered Scraper', 'universal-scraper'),
        getItem('HubSpot', null, null, [
          getItem('HubSpot Contact Emails Finder', 'hubspot-contact-emails-finder'),
          getItem('HubSpot Company Contacts Finder', 'hubspot-company-contacts-finder'),
        ], 'group'),
        getItem('Geocoding', null, null, [
          getItem('Geocoding', 'geocoding'),
          getItem('Reverse Geocoding', 'reverse-geocoding'),
        ], 'group'),
      ]),
      ...(admin ? [
        getItem('Internal Services', 'internal', null, [
          getItem('Places DB Search', 'places-search'),
          getItem('Places DB Update', 'places-update'),
          getItem('Domain Contacts Finder', 'domain-contacts-finder'),
        ])
      ] : []),
      !isEmpty(starredServicesArray) && getItem(t('title.favorites', 'Favorites'), 'favorites', <StarFilled />, [
        ...starredServicesArray.map((service) => {
          const { title, href } = service;
          return getItem(title, href + '?tab=favorite');
        }),
      ]),
      !isEmpty(recentServicesArray) && getItem(t('title.recentlyVisited', 'Recently Visited'), 'recently-visited', <ClockCircleOutlined />, [
        ...recentServicesArray.map((service) => {
          const { title, href } = service;
          return getItem(title, href + '?tab=recent');
        }),
      ]),
    ]),

    ...starredServicesArray.map((service) => {
      const { title, href } = service;
      return getItem(title, href);
    }),

    getItem(t('title.tasks', 'Tasks'), 'tasks', <FontAwesomeIcon icon={faTasks}/>),
    getItem(authenticated ? showUserName(email) : t('title.profile', 'Profile'), 'profile-items', authenticated ? <Avatar src={avatar ? avatar : `https://gravatar.com/avatar/${md5(email)}?size=128`} size='small'/> : <FontAwesomeIcon icon={faUser}/>, [
      getItem(t('title.profile', 'Profile'), 'profile', <FontAwesomeIcon icon={faUser}/>),
      ...(isTenant ? [] : [
        getItem(t('title.billingInformation', 'Billing Information'), 'billing-info', <FontAwesomeIcon icon={faCreditCard}/>),
      ]),
      getItem(t('title.invoices', 'Invoices'), 'invoices', <FontAwesomeIcon icon={faFileInvoiceDollar}/>),
      getItem(t('title.logOut', 'Log Out'), 'logout', <FontAwesomeIcon icon={faSignOutAlt}/>)
    ]),
    getItem('API' + ' & ' + t('title.integrations', 'Integrations'), 'api-integrations', <ApiOutlined />, [
      getItem(t('title.apiDocs', 'API Docs'), 'api-docs', <FontAwesomeIcon icon={faFileAlt}/>),
      getItem(t('title.apiUsage', 'API Usage History'), 'api-usage', <FontAwesomeIcon icon={faTable}/>),
      getItem(t('title.integrations', 'Integrations'), 'integrations', <FontAwesomeIcon icon={faPlug}/>),
    ]),
    getItem(t('title.help', 'Help'), 'help', <FontAwesomeIcon icon={faQuestion}/>, [
      getItem('F.A.Q.', config.get('faqUrl'), <FontAwesomeIcon icon={faQuestion}/>),
      getItem(t('title.tutorials', 'Tutorials'), config.get('tutorialsUrl'), <FontAwesomeIcon icon={faGraduationCap}/>),
      getItem(t('title.videos', 'Videos'), config.get('videosUrl'), <FontAwesomeIcon icon={faVideo}/>),
      getItem(t('title.askCommunity', 'Ask Community'), config.get('communityUrl'), <FontAwesomeIcon icon={faUsers}/>),
      getItem(t('title.support', 'Support'), 'support', isHelpOpened ? <FontAwesomeIcon icon={faTimesCircle}/> : <FontAwesomeIcon icon={faComments}/>),
      ...(isCredibleUser() ? [getItem(t('title.schedule', 'Schedule a Call'), 'https://outscraper.com/support/#schedule', <FontAwesomeIcon icon={faPhone}/>)] : []),
      getItem(t('title.reportProblem', 'Report a Problem'), 'report', <FontAwesomeIcon icon={faBug}/>),
      getItem(t('title.pricing', 'Pricing'), config.get('pricingUrl'), <FontAwesomeIcon icon={faDollarSign}/>),
    ]),
  ];

  return <>
    <Layout.Sider
      collapsible
      width='220'
      className='sider'
      style={menuStyle}
      collapsed={collapsed}
      onCollapse={onSetCollapsed}
    >
      <Menu theme={theme} style={menuStyle} selectedKeys={selectedKeys} mode='vertical' items={items} onClick={onMenuClick} />
    </Layout.Sider>
    <FeatureRequestModal bug open={featureRequestOpen} onChange={setFeatureRequestOpen}/>
  </>;
}

Sider.propTypes = {
  auth: PropTypes.object.isRequired,
};
