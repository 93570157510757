import React from 'react';
import PropTypes from 'prop-types';
import { Card, Descriptions, Badge } from 'antd';

export default function Results({ value }) {
  const { status, valid_format, in_blacklist, valid_dns, valid_smtp, query } = value;

  return (
    <Card style={{ marginTop: 15 }}>
      <Descriptions>
        <Descriptions.Item label='Email' span={2}>{query}</Descriptions.Item>
        <Descriptions.Item label='Status' span={1}>
          {status === 'RECEIVING' ? <Badge status='success' text={status} /> : <Badge status='error' text={status} />}
        </Descriptions.Item>
        <Descriptions.Item label='Valid format' span={0.75}>{valid_format ? 'Yes' : 'No'}</Descriptions.Item>
        <Descriptions.Item label='Blacklisted' span={0.75}>{in_blacklist ? 'Yes' : 'No'}</Descriptions.Item>
        <Descriptions.Item label='Valid DNS' span={0.75}>{valid_dns ? 'Yes' : 'No'}</Descriptions.Item>
        <Descriptions.Item label='Valid SMTP' span={0.75}>{valid_smtp ? 'Yes' : 'No'}</Descriptions.Item>
      </Descriptions>
    </Card>
  );
}

Results.propTypes = {
  value: PropTypes.object.isRequired,
};
