import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

import CountrySelect from '../CountrySelect';

function RegionSelect({ value, onChange, hideTooltip = false, allowClear = true }) {
  return <CountrySelect
    value={value}
    onChange={onChange}
    tooltip={!hideTooltip && <Trans i18nKey={'description.selectCountry'}>Parameter specifies the country to use for Google site.<br/><br/>When you are using direct links or IDs to places you might want to ignore this option as it will overwrite the links <em>ql</em> parameter and filter out items from other countries.<br/><br/> It&apos;s still necessary to provide the country inside queries.</Trans>}
    allowClear={allowClear}
  />;
}

RegionSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  hideTooltip: PropTypes.bool,
  allowClear: PropTypes.bool,
};

export default React.memo(RegionSelect, (prevProps, nextProps) => {
  return prevProps.value === nextProps.value;
});
