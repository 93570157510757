import React from 'react';

import PlainQueriesSwitch from './PlainQueriesSwitch';

export default function plainQueriesSwitchFormHeader(task, updateTask, key='customQueries') {
  const { UISettings = {} } = task;
  const { isCustomQueries } = UISettings;

  function onSetIsCustomQueries(value) {
    updateTask({ UISettings: { ...UISettings, isCustomQueries: value } });
    localStorage.setItem(key, value ? '1' : '0');
  }

  return <PlainQueriesSwitch checked={!!isCustomQueries} onChange={onSetIsCustomQueries} />;
}