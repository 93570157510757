import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { TreeSelect, Descriptions, Badge, Tooltip } from 'antd';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';

import { getServiceUrl } from '../../../utils/utils';
import { checkIfNewService } from '../../../shared/data/services';
import { services, serviceGroups } from '../../../shared/data/services';
import PricingCards from '../../../shared/Components/PricingCards';

const UNIT_EMOJIS = {
  'phone': '☎️',
  'email': '📧',
  'address': '🏠',
  'domain': '🌐',
  'company': '🏢',
  'biz': '🏢',
};

const StyledHeader = styled.span`
  font-weight: bold;
  font-size: 14px;
`;

const StyledDescription = styled.div`
  padding-left: 8px;
  color: #00000073;
  font-size: 14px;
`;

const StyledTreeSelect = styled(TreeSelect)`
  max-width: 765px;
  min-width: 665px;

  @media (min-width: 1000px) {
    min-width: 765px;
  }

  @media (max-width: 900px) {
    min-width: 665px;
  }

  @media (max-width: 850px) {
    min-width: 630px;
  }

  @media (max-width: 800px) {
    min-width: 600px;
  }

  @media (max-width: 750px) {
    min-width: 550px;
  }

  @media (max-width: 700px) {
    min-width: 500px;
  }

  @media (max-width: 650px) {
    min-width: 450px;
  }

  @media (max-width: 600px) {
    min-width: 400px;
  }
`;

function SelectService({ value, onChange, serviceName, region, supportedEnrichmentServices, exludedEnrichmentServices, serviceOntologies }) {
  const { t } = useTranslation();
  const supportedEnrichmets = useMemo(() => new Set(supportedEnrichmentServices || []), [supportedEnrichmentServices]);
  const supportedOntologies = useMemo(() => getSupportedOntologies(value || []), [value, serviceOntologies]);
  const recentNewServices = useMemo(() => {
    const recentNewServices = localStorage.getItem('recentNewEnrichments');
    return recentNewServices ? JSON.parse(recentNewServices) : [];
  }, []);

  useEffect(() => {
    if (value.length > 0) {
      value.forEach(service => {
        const serviceUrl = getServiceUrl(service);
        if (checkIfNewService(serviceUrl, recentNewServices)) markServiceAsViewed(serviceUrl);
      });
    }
  }, [value, recentNewServices]);

  function getSupportedOntologies(value) {
    const selectedServicesSupportedOntologies = [];
    value.forEach(service => {
      const currentService = services[service];
      if (currentService) {
        const outputOntologies = currentService.output_ontologies || [];
        const outputFields = currentService.output_fields || [];
        selectedServicesSupportedOntologies.push(...[...outputOntologies, ...outputFields]);
      }
    });

    return new Set([
      ...(serviceOntologies || []),
      ...selectedServicesSupportedOntologies,
      ...((services[serviceName] || {}).input_fields || []),
      ...((services[serviceName] || {}).output_fields || []),
      ...((services[serviceName] || {}).output_ontologies || []),
    ]);
  }

  function handleOnChange(value) {
    const supportedOntologies = getSupportedOntologies(value);
    onChange(value.filter(v => showService(v, services[v], supportedOntologies)));
  }

  function showService(inputServiceName, service, ontologies = supportedOntologies) {
    if (!isEmpty(supportedEnrichmets) && supportedEnrichmets.has(inputServiceName)) return true;

    if (inputServiceName === serviceName) return false;

    if (region && service.supported_regions && !service.supported_regions.includes(region)) return false;

    if (service.incompatible_with && value.some(v => service.incompatible_with.includes(v))) return false;

    if (!isEmpty(ontologies) && !isEmpty(service.input_fields) && service.input_fields.some(ontology => ontologies.has(ontology)) && (!exludedEnrichmentServices || !exludedEnrichmentServices.includes(inputServiceName))) {
      return true;
    }

    if (service.supported_services && service.supported_services.includes(serviceName)) return true;

    return false;
  }

  function handleOnSelect(value) {
    const serviceUrl = getServiceUrl(value);
    if (checkIfNewService(serviceUrl, recentNewServices)) markServiceAsViewed(serviceUrl);
  }

  function markServiceAsViewed(serviceUrl) {
    localStorage.setItem('recentNewEnrichments', JSON.stringify([...recentNewServices, serviceUrl]));
  }

  const treeData = useMemo(() => {
    let newServices = 0;

    const data = Object.entries(serviceGroups).flatMap(([groupId, groupDescription]) => {
      const groupServices = Object.entries(services)
        .filter(([, service]) => service.groups && service.groups.includes(groupId))
        .filter(([serviceId, service]) => showService(serviceId, service))
        .map(([serviceId, service]) => {
          const serviceName = service.name;
          const serviceUrl = getServiceUrl(serviceId);

          var isNewService = false;
          if (checkIfNewService(serviceUrl, recentNewServices) && !value.includes(serviceId)) {
            isNewService = true;
            newServices += 1;
          }

          return ({
            value: serviceId,
            label: <Trans i18nKey={`title.${serviceName}`}>{serviceName}</Trans>,
            title: (
              <Tooltip title={isNewService ? 'New' : null} color='red' placement='topRight'>
                <Badge dot={isNewService} offset={[-20, 0]} title='New enrichment'>
                  <Descriptions size='small' column={1}>
                    <Descriptions.Item>
                      <StyledHeader>
                        <EnrichmentIcon service={service}/>
                        <Trans i18nKey={`title.${serviceName}`}>{serviceName}</Trans>
                      </StyledHeader>
                      <StyledDescription>
                        <Trans i18nKey={`description.${serviceId}`}>{service.description}</Trans>.
                      </StyledDescription>
                    </Descriptions.Item>
                    <Descriptions.Item>
                      <PricingCards size='small' value={service.prices} unitName={<Trans i18nKey={`unit.${service.unit || 'item'}`}>{service.unit || 'item'}</Trans>}/>
                    </Descriptions.Item>
                  </Descriptions>
                </Badge>
              </Tooltip>),
          });
        });

      if (groupServices.length === 0) return [];
      return { title: groupDescription, value: groupId, selectable: false, checkable: false, children: groupServices };
    });

    return { data, newServices };
  }, [value, recentNewServices, serviceName, region, supportedEnrichmets]);

  return <>
    <Badge count={treeData.newServices} size='small' overflowCount={9} title={`${treeData.newServices} new enrichment(s)`}>
      <StyledTreeSelect
        allowClear
        showSearch
        treeCheckable
        treeDefaultExpandAll
        maxTagCount={3}
        value={value}
        placeholder={t('description.addEnrichments', 'Add services to the data pipeline')}
        onChange={handleOnChange}
        onSelect={handleOnSelect}
        treeData={treeData.data}
        treeNodeLabelProp='label'
      />
    </Badge>
  </>;
}

SelectService.propTypes = {
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  serviceName: PropTypes.string,
  region: PropTypes.string,
  supportedEnrichmentServices: PropTypes.array,
  exludedEnrichmentServices: PropTypes.array,
  serviceOntologies: PropTypes.array,
};

function EnrichmentIcon({ service }) {
  const { icon, unit } = service;
  const unitEmoji = icon || UNIT_EMOJIS[unit];

  return <>{unitEmoji && unitEmoji + ' '}</>;
}

EnrichmentIcon.propTypes = {
  service: PropTypes.object.isRequired,
};

export default React.memo(SelectService, (prevProps, nextProps) => {
  return prevProps.value === nextProps.value && prevProps.region === nextProps.region;
});
