import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import InfoTooltip from '../../../shared/Components/InfoTooltip';

import SelectService from '../Pipeline/SelectService';

function EnrichmentsItem({ value, onChange, supportedEnrichmentServices, exludedEnrichmentServices, title,
  learnMoreUrl, serviceName, serviceOntologies }) {
  const { t } = useTranslation();
  const localTitle = t(`title.${title}`, title);

  return (
    <Form.Item label={t('title.enrichmentServices', 'Enhance results with other services')} className='formItem'>
      <span className='nobreak'>
        <SelectService
          value={value}
          onChange={onChange}
          serviceName={serviceName}
          serviceOntologies={serviceOntologies}
          supportedEnrichmentServices={supportedEnrichmentServices}
          exludedEnrichmentServices={exludedEnrichmentServices}
        />
        <InfoTooltip title={<Trans i18nKey='description.enrichments'>
          There is a limited amount of <a target='_blank' rel='noopener noreferrer' href={`${learnMoreUrl}/#dictionary`}>fields</a> you can get from {{localTitle}}. However, you can enrich the data with other tools by adding the services you need to the scraping pipeline.
          <a target='_blank' rel='noopener noreferrer' href='https://outscraper.com/enrichment-services/'> Learn more</a>
        </Trans>}/>
      </span>
    </Form.Item>
  );
}

EnrichmentsItem.propTypes = {
  title: PropTypes.string,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  serviceName: PropTypes.string.isRequired,
  supportedEnrichmentServices: PropTypes.array.isRequired,
  exludedEnrichmentServices: PropTypes.array,
  serviceOntologies: PropTypes.array,
  learnMoreUrl: PropTypes.string,
};

export default React.memo(EnrichmentsItem, (prevProps, nextProps) => {
  return prevProps.value === nextProps.value;
});
