import React from 'react';

import SimpleService from './Common/SimpleService';

export default function YouTubeSearchScraper() {
  return (
    <SimpleService
      unitName='search'
      title='YouTube Search Scraper'
      subTitle='Extracts search results'
      serviceName='youtube_search_service'
      apiTag='YouTube/paths/~1youtube-search/get'
      queriesPlaceholder='funny cats videos'
      learnMoreUrl='https://outscraper.com/youtube-search-scraper'
      supportedEnrichmentServices={['youtube_channel_service']}
    />
  );
}
