import React from 'react';

import { services } from '../../../shared/data/services';
import SimpleService from '../Common/SimpleService';

export default function EmailsValidator() {
  return (
    <SimpleService
      unitName='email'
      title='Email Address Verifier'
      subTitle='Validates email addresses, checks deliverability'
      serviceName='emails_validator_service'
      queriesPlaceholder='hello@outscraper.com&#10;support@outscraper.com'
      queriesLabel='Email addresses'
      limitType={null}
      learnMoreUrl='https://outscraper.com/email-list-cleaning/'
      tutorialLink='https://outscraper.com/validate-email-addresses-bulk/'
      apiTag='Email-Related/paths/~1email-validator/get'
      enrichmentFields={services.emails_validator_service.input_fields}
      supportedEnrichmentServices={[]}
    />
  );
}
