import React from 'react';

import SimpleService from './Common/SimpleService';

export default function YelpScraper() {
  return (
    <SimpleService
      unitName='business'
      title='Y.E.L.P Businesses Scraper'
      subTitle='Extracts data from a list of businesses'
      serviceName='yelp_service'
      queriesPlaceholder='https://www.yelp.com/search?cflt=restaurants&find_loc=San+Francisco%2C+CA&#10;https://www.yelp.com/search?cflt=Plumbing&find_loc=San+Francisco%2C+CA'
      learnMoreUrl='https://outscraper.com/yelp-scraper'
      apiTag='Businesses-and-POI/paths/~1yelp-search/get'
      fields={['biz_id', 'name', 'price_range', 'rating', 'reviews', 'categories', 'neighborhoods', 'phone', 'website', 'latitude', 'longitude', 'city', 'state', 'country', 'business_url', 'services', 'photo', 'tags', 'snippet']}
      supportedEnrichmentServices={[]}
      serviceOntologies={['domain', 'phone', 'address']}
    />
  );
}
