import React from 'react';

import { Form, Select } from 'antd';
import SimpleService from './Common/SimpleService';

const { Option } = Select;

export default function TripadvisorSearch() {
  return (
    <SimpleService
      unitName='place'
      title='Tripadvisor Search Scraper'
      subTitle='Extracts data from Tripadvisor'
      serviceName='tripadvisor_search'
      queriesPlaceholder='rome&#10;https://www.tripadvisor.com/Search?q=rome'
      apiTag='Others/paths/~1tripadvisor-search/get'
      maxLimit={1000}
      ExtraItems={({ updateTask, task }) => {
        const { search_type = 'a' } = task;

        return <>
          <Form.Item label='Search type'>
            <Select value={search_type} style={{ width: 150 }} onChange={(v) => updateTask({ search_type: v })}>
              <Option key='a' value='a'>All Results</Option>
              <Option key='h' value='h'>Hotels</Option>
              <Option key='e' value='e'>Restaurants</Option>
              <Option key='A' value='A'>Things to Do</Option>
              <Option key='v' value='v'>Vacation Rentals</Option>
            </Select>
          </Form.Item>
        </>;
      }}
    />
  );
}
