import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tag, Button } from 'antd';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

import { CITY_FILTER_REGIONS, CITY_FILTER_FIELDS } from './EditFilter';
import EditFilter from './EditFilter';
import PremiumFeatureTooltip from '../../../../shared/Components/PremiumFeatureTooltip';

const StyledSpan = styled.span`
  font-size: 10px;
  padding-right: 7px;
`;

function Filters({ value: filters = [], exactMatch, onChange, disabled = false, region, isPaidUser }) {
  useEffect(() => {
    if (disabled) {
      onChange({ filters: [] });
    }
  }, [disabled]);

  useEffect(() => {
    if (!region || !CITY_FILTER_REGIONS.has(region)) {
      for (var i = filters.length - 1; i >= 0; i--) {
        if (CITY_FILTER_FIELDS.includes(filters[i].key)) {
          filters.splice(i, 1);
        }
      }
    }
  }, [region]);

  function handleOnChange(v, i) {
    const newFilters = JSON.parse(JSON.stringify(filters));
    newFilters[i] = v;
    onChange({ filters: newFilters });
  }

  function onAddFilter(v) {
    onChange({ filters: [ ...filters, v ] });
  }

  function onRemoveFilter(i) {
    const newFilters = JSON.parse(JSON.stringify(filters));
    newFilters.splice(i, 1);

    if (exactMatch && newFilters.length === 0) {
      onChange({ filters: newFilters, exactMatch: false });
    } else {
      onChange({ filters: newFilters });
    }
  }

  return <>
    {filters.map((el, i) =>
      <span key={`${el.key}-${el.operator}-${el.value}-${i}`}>
        {i > 0 && <StyledSpan>AND</StyledSpan>}
        <Tag
          closable
          key={`${el.key}-${el.operator}-${el.value}-${i}`}
          onClose={() => onRemoveFilter(i)}
        >
          <EditFilter
            defaultKey={el.key}
            defaultValue={el.value}
            defaultOperator={el.operator}
            index={i}
            onChange={handleOnChange}
            region={region}
          >
            <span style={{ cursor: 'pointer' }}>
              {el.key}: {el.operator} {el.value && <>&quot;{el.value.join(', ')}&quot;</>}
            </span>
          </EditFilter>
        </Tag>
      </span>
    )}

    <PremiumFeatureTooltip isPaidUser={isPaidUser}>
      <EditFilter disabled={disabled || !isPaidUser} region={region} onChange={onAddFilter}>
        <Button disabled={disabled || !isPaidUser} size='small' type='link'>+&nbsp;<Trans i18nKey='title.add'>Add</Trans></Button>
      </EditFilter>
    </PremiumFeatureTooltip>
  </>;
}

Filters.propTypes = {
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  exactMatch: PropTypes.bool,
  disabled: PropTypes.bool,
  region: PropTypes.string,
  isPaidUser: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
};

export default React.memo(Filters, (prevProps, nextProps) => {
  return (
    prevProps.region === nextProps.region &&
    prevProps.isPaidUser === nextProps.isPaidUser &&
    prevProps.disabled === nextProps.disabled &&
    prevProps.exactMatch === nextProps.exactMatch &&
    arraysAreEqual(prevProps.value, nextProps.value)
  );
});


function arraysAreEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    const obj1 = arr1[i];
    const obj2 = arr2[i];

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (!keys2.includes(key)) {
        return false;
      }

      const value1 = obj1[key];
      const value2 = obj2[key];

      if (!arraysOfStringsAreEqual(value1, value2)) {
        return false;
      }
    }
  }
  return true;
}

function arraysOfStringsAreEqual(array1, array2) {
  if (!Array.isArray(array1) || !Array.isArray(array2)) {
    return false;
  }

  if (array1.length !== array2.length) {
    return false;
  }

  const sortedArray1 = array1.slice().sort();
  const sortedArray2 = array2.slice().sort();

  for (let i = 0; i < sortedArray1.length; i++) {
    if (sortedArray1[i] !== sortedArray2[i]) {
      return false;
    }
  }

  return true;
}
