/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from 'antd';
import { QuestionCircleTwoTone } from '@ant-design/icons';

function InfoTooltip({ title, paddingLeft=10, paddingRight=0 }) {
  return <Tooltip title={title}><QuestionCircleTwoTone style={{ paddingLeft, paddingRight }}/></Tooltip>;
}

InfoTooltip.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  paddingLeft: PropTypes.number,
  paddingRight: PropTypes.number,
};

export default React.memo(InfoTooltip, (prevProps, nextProps) => {
  return true;
});
