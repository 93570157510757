import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, notification } from 'antd';
import { useTranslation, Trans } from 'react-i18next';

import apiProfile from '../api/apiProfile';
import LoginSignupForm from './Common/LoginSignupForm';
import EmailFormItem from './Common/EmailFormItem';
import PasswordFormItem from './Common/PasswordFormItem';

export default function Login({ auth, history, location }) {
  const [checkEmail, setCheckEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  if (window.top !== window.self) {
    window.top.location = window.location;
  }

  async function onFinish(payload) {
    setLoading(true);

    try {
      const { data } = await apiProfile.getAuthToken(payload);
      const { confirm_email, access_token } = data;

      if (access_token) {
        auth.setSession(access_token, true);
      } else if (confirm_email) {
        notification.success({ message: t('title.checkInbox', 'Check Your Inbox') });
        setCheckEmail(payload['email']);
      }

    } catch (error) {
      notification.error({ message: error.message });
    } finally {
      setLoading(false);
    }
  }

  function toSignUp(){
    history.push('signup');
  }

  function toResetPassword(){
    history.push('reset-password');
  }

  return <LoginSignupForm
    captcha
    title={t('title.welcome', 'Welcome to Outscraper')}
    subTitle={t('description.logIn', 'Log In to Outscraper Platform')}
    loading={loading}
    location={location}
    onFinish={onFinish}
    checkEmail={checkEmail}
    AfterButtonComponent={() => <><Trans i18nKey='description.doNotHaveAccount'>Don&apos;t have an account?</Trans> <Button style={{ marginLeft: -15 }} type='link' onClick={toSignUp}><Trans i18nKey='title.signUp'>Sign Up</Trans></Button></>}
  >
    <EmailFormItem />
    <PasswordFormItem />
    <Form.Item><Button type='link' style={{ float: 'right', marginRight: -15 }} onClick={toResetPassword}><Trans i18nKey='title.forgotPassword'>Forgot Password?</Trans></Button></Form.Item>
  </LoginSignupForm>;
}

Login.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};
